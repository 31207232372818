export default [
  {
    title: "Ana Sayfa",
    route: "home",
    icon: "HomeIcon",
  },
  {
    header: 'Çalışanları Düzenle',
    icon: 'EditIcon',
    children: [
      {
        title: "Çalışan",
        route: "calisanlar",
        icon: "UsersIcon",
      }, 
      {
        title: "Görev Atama",
        route: "calisangorev",
        icon: "UsersIcon",
      }, 
      {
        title: "Görev Oluştur",
        route: "gorevTanimla",
        icon: "UsersIcon",
      }, 
    ],
  }, 
  {
    header: 'Hastane Düzenle',
    icon: 'EditIcon',
    children: [
      {
        title: "Hastane",
        route: "hastaneTanimla",
        icon: "UsersIcon",
      }, 
      {
        title: "Departman",
        route: "departmanTanimla",
        icon: "UsersIcon",
      }, 
      {
        title: "Hastane Departmanı",
        route: "hastaneDepartman",
        icon: "UsersIcon",
      }, 
      {
        title: "Departman Görevleri",
        route: "departmanGorev",
        icon: "UsersIcon",
      }, 
       
    ],
  }, 
  {
    header: 'Tanımlamalar',
    icon: 'EditIcon',
    children: [
      {
        title: "Tedavi Bölümleri",
        route: "tedaviBolumleri",
        icon: "UsersIcon",
      },  
      {
        title: "Referans Kanalları",
        route: "referanceChannel",
        icon: "UsersIcon",
      }, 
      {
        title: "İletişim Kanalları",
        route: "contactChannel",
        icon: "UsersIcon",
      },
      {
        title: "Hasta Rahatsızlığı",
        route: "discomFort",
        icon: "UsersIcon",
      }, 
      {
        title: "Hasta Talebi",
        route: "want",
        icon: "UsersIcon",
      },    
      {
        title: "İlçeler",
        route: "district",
        icon: "UsersIcon",
      }, 
       {
        title: "iller",
        route: "province",
        icon: "UsersIcon",
      }, 
      {
        title: "Sonuçlar",
        route: "result",
        icon: "CheckIcon",
      },  
      
    ],
  }, 
  {
    header: 'Geçmiş',
    icon: 'EditIcon',
    children: [
      {
        title: "Hasta Bilgileri Düzenle",
        route: "patient",
        icon: "EditIcon",
      },
      {
        title: "Geçmiş Çağrı Kaydı",
        route: "cagrikayitlistele",
        icon: "ListIcon",
      },
     
      
    ],
  },  
  {
    title: "Çağrı Kaydı",
    route: "cagrikayit",
    icon: "SaveIcon",
  },
 
]
